<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card elevation="10" :loading="loading" class="ma-4" width="100%">
        <v-card-title>
          Your Watchlists
          <v-spacer />
          <v-select
            :loading="selectLoading"
            :items="watchlists"
            :item-text="'name'"
            :item-value="'pos'"
            :disabled="loading"
            v-model="selectedWatchlist"
            single-line
            hide-details
            label="Select Watchlist"
            outlined
            id="watchlist-selector"
            @change="watchlistChanged"
          >
            <template v-slot:prepend>
              <v-icon
                v-if="!isNewWatchlist"
                color="error"
                @click=";(deleteWatchlist = true), (dialog = true)"
                v-text="'mdi-delete'"
              ></v-icon>
            </template>
          </v-select>
          <v-autocomplete
            v-model="selectedTickers"
            :items="tickers"
            :disabled="isNewWatchlist"
            :loading="tickerSearchLoading"
            :search-input.sync="tickerQuery"
            :filter="tickerFilter"
            class="ml-4"
            hide-no-data
            hide-details
            hide-selected
            item-text="symbol"
            item-value="symbol"
            label="Add tickers"
            style="width: 8em;"
            multiple
            chips
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="removeSelectedTicker(data.item)"
              >
                {{ data.item.symbol }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.symbol"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-if="data.item.longname"
                  v-html="data.item.longname"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template v-slot:append-outer>
              <v-icon
                v-if="selectedTickers.length !== 0"
                color="success"
                @click="addTickers"
                v-text="'mdi-plus'"
              ></v-icon>
            </template>
          </v-autocomplete>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="stocks"
          :search="search"
          no-data-text="No stocks found!"
        >
          <template v-slot:[`item.l52`]="{ item }">
            <span class="error--text">{{ item.l52 }}</span>
          </template>
          <template v-slot:[`item.h52`]="{ item }">
            <span class="success--text">{{ item.h52 }}</span>
          </template>
          <template v-slot:[`item.dh`]="{ item }">
            <span class="success--text">{{ item.dh }}</span>
          </template>
          <template v-slot:[`item.dl`]="{ item }">
            <span class="error--text">{{ item.dl }}</span>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <span
              :class="
                item.updated === 'up'
                  ? 'success--text'
                  : item.updated === 'down'
                  ? 'error--text'
                  : ''
              "
            >
              {{ item.price }}
            </span>
          </template>
          <template v-slot:[`item.chgp`]="{ item }">
            <span :class="item.chgp > 0.0 ? 'success--text' : 'error--text'">
              {{ item.chgp }}
            </span>
          </template>
          <template v-slot:[`item.hlp`]="{ item }">
            <span :class="item.hlp > 1.0 ? 'success--text' : 'error--text'">
              {{ item.hlp }}
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="deleteTicker(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog
        v-model="dialog"
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card>
          <v-card-title>
            <span
              class="headline"
              v-html="
                deleteWatchlist && !isNewWatchlist
                  ? `Delete watchlist ${
                      this.watchlists[this.selectedWatchlist].name
                    }?`
                  : 'Create new watchlist'
              "
            ></span>
          </v-card-title>

          <v-card-text v-if="!deleteWatchlist">
            <v-form
              ref="form"
              @submit="addWatchlist"
              v-model="valid"
              lazy-validation
              class="mx-0"
            >
              <v-text-field
                v-model="watchlistName"
                label="Watchlist Name"
                :rules="watchlistRules"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close"> Cancel </v-btn>
            <v-btn
              v-if="!deleteWatchlist"
              color="green darken-1"
              text
              :disabled="!valid"
              @click="addWatchlist"
            >
              Save
            </v-btn>
            <v-btn v-else color="error" text @click="removeWatchlist"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogDelete"
        width="auto "
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card>
          <v-card-title
            class="headline"
            v-html="
              `Are you sure you want to delete ${deleteItem.symbol || ''}?`
            "
          >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDelete">Cancel</v-btn>
            <v-btn color="red darken-1" text @click="deleteTickerConfirm">
              YES
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col class="text-center text-h4" lg="3" md="4" sm="6" cols="9">
        <StatusSnackbar
          :show="showSnackbar"
          :text="snackbarText"
          :type="snackbarType"
          @close="showSnackbar = false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
input#watchlist-selector {
  position: absolute;
  display: inline;
  width: 0;
  height: 0;
  padding: 0;
}
.v-input__prepend-outer button {
  width: 24px;
  height: 24px;
}
</style>

<script>
import _ from 'lodash'
import axios from 'axios'
import { auth, usersCollection } from '@/firebase'
import StatusSnackbar from '@/components/StatusSnackbar'
import YFinance from 'yfinance-live'

export default {
  name: 'Watchlist',
  components: {
    StatusSnackbar,
  },
  computed: {
    isNewWatchlist() {
      return (
        this.selectedWatchlist === undefined || this.selectedWatchlist === 0
      )
    },
  },
  data: () => ({
    loading: true,
    selectLoading: true,
    search: '',
    headers: [
      { text: 'Company', value: 'company', align: 'start', width: '300px' },
      { text: 'Symbol', value: 'symbol', align: 'start' },
      {
        text: '52W Low',
        value: 'l52',
        align: 'end',
        class: 'error--text',
      },
      {
        text: '52W High',
        value: 'h52',
        align: 'end',
        class: 'success--text',
      },
      { text: 'Open', value: 'open', align: 'end' },
      { text: 'Day High', value: 'dh', align: 'end', class: 'success--text' },
      { text: 'Day Low', value: 'dl', align: 'end', class: 'error--text' },
      { text: 'Curr.', value: 'price', align: 'end' },
      { text: 'Chg.%', value: 'chgp', align: 'end' },
      { text: 'H%/L%', value: 'hlp', align: 'end' },
      {
        text: ' ',
        value: 'actions',
        width: '30px',
        align: 'center',
        sortable: false,
      },
    ],
    selectedWatchlist: undefined,
    watchlists: [],
    stocks: [],
    tickers: [],
    tickerQuery: '',
    selectedTickers: [],
    tickerSearchLoading: false,
    watchlistName: null,
    deleteIndex: -1,
    deleteItem: {},
    deleteWatchlist: false,
    dialog: false,
    dialogDelete: false,
    watchlistRules: [v => !!v || 'Watchlist name is required'],
    valid: false,
    showSnackbar: false,
    snackbarType: 'success',
    snackbarText: '',
    yfinancelive: null,
  }),
  watch: {
    tickerQuery() {
      if (this.tickerQuery && this.tickerQuery.length > 0) {
        this.debouncedGetTickerResults()
      } else {
        this.tickers = this.selectedTickers.map(ticker => {
          return {
            symbol: ticker,
          }
        })
      }
    },
    selectedTickers(newSelectedTickers, oldSelectedTickers) {
      if (newSelectedTickers.length > oldSelectedTickers.length) {
        this.tickerQuery = ''
      } else {
        this.tickers = this.selectedTickers.map(ticker => {
          return {
            symbol: ticker,
          }
        })
      }
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  created() {
    this.debouncedGetTickerResults = _.debounce(this.getTickerResults, 300)
  },
  methods: {
    getTickerResults() {
      this.tickerSearchLoading = true
      axios
        .get(`/api/searchtickers?q=${this.tickerQuery}`)
        .then(res => {
          this.tickers = res.data.concat(
            this.selectedTickers.map(ticker => {
              return {
                symbol: ticker,
              }
            })
          )
        })
        .finally(() => {
          this.tickerSearchLoading = false
        })
    },
    async fetchWatchlists() {
      let watchlists = []
      return usersCollection
        .doc(auth.currentUser.uid)
        .collection('watchlists')
        .get()
        .then(querySnapshot => {
          watchlists.push({
            id: 'create_new',
            name: 'Create new watchlist',
            pos: 0,
          })
          querySnapshot.forEach(doc => {
            watchlists.push({
              ...doc.data(),
              id: doc.id,
              pos: watchlists.length,
            })
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.watchlists = watchlists
          if (watchlists.length > 1) {
            this.selectedWatchlist = 1
          }
          this.selectLoading = false
        })
    },
    fetchStockData() {
      let axiosReqs = []
      let chunkSize = 101
      for (
        let i = 0,
          j = this.watchlists[this.selectedWatchlist].constituents.length;
        i < j;
        i += chunkSize
      ) {
        axiosReqs.push(
          axios.get('/api/stocksdata', {
            params: {
              symbols: JSON.stringify(
                this.watchlists[this.selectedWatchlist].constituents.slice(
                  i,
                  i + chunkSize
                )
              ),
            },
          })
        )
      }
      axios
        .all(axiosReqs)
        .then(
          axios.spread((...responses) => {
            this.stocks = [].concat.apply(
              [],
              responses.map(res => res.data.data)
            )
          })
        )
        .catch(err => {
          this.snackbarText = `Error fetching stocks: ${err.message}`
          this.snackbarType = 'error'
          this.showSnackbar = true
        })
        .then(() => {
          this.loading = false
          this.snackbarText = `Successfully fetched stocks`
          this.snackbarType = 'success'
          this.showSnackbar = true
          this.yfinancelive = YFinance(
            this.stocks.map(stock => stock.symbol),
            this.handleLiveStockData
          )
        })
    },
    clearPriceUpdated(stockIdx) {
      this.stocks[stockIdx].updated = null
    },
    handleLiveStockData(data) {
      let stockIdx = this.stocks.findIndex(stock => stock.symbol === data.id)
      clearTimeout(this.stocks[stockIdx].timeout)
      if (data.price.toFixed(2) != this.stocks[stockIdx].price) {
        this.stocks[stockIdx].updated =
          data.price.toFixed(2) > this.stocks[stockIdx].price ? 'up' : 'down'
        this.stocks[stockIdx].timeout = setTimeout(
          () => this.clearPriceUpdated(stockIdx),
          1000
        )
      }
      this.stocks[stockIdx].price = data.price.toFixed(2)
      if (this.stocks[stockIdx].price > this.stocks[stockIdx].high) {
        this.stocks[stockIdx].high = data.price.toFixed(2)
      }
      if (this.stocks[stockIdx].price < this.stocks[stockIdx].low) {
        this.stocks[stockIdx].high = data.price.toFixed(2)
      }
      this.stocks[stockIdx].chgp = (
        (100 * (data.price.toFixed(2) - this.stocks[stockIdx].open)) /
        this.stocks[stockIdx].open
      ).toFixed(3)
    },
    // eslint-disable-next-line no-unused-vars
    tickerFilter(item, queryText, itemText) {
      return true
    },
    addWatchlist(e) {
      if (e) e.preventDefault()
      let newWatchlist = { name: this.watchlistName, constituents: [] }
      let usersWatchlistsRef = usersCollection
        .doc(auth.currentUser.uid)
        .collection('watchlists')
      let newWatchlistRef = usersWatchlistsRef.doc(`${Date.now()}`)
      newWatchlistRef
        .set(newWatchlist)
        .then(() => {
          this.watchlists.push({
            id: newWatchlistRef.id,
            ...newWatchlist,
            pos: this.watchlists.length,
          })
          this.selectedWatchlist = this.watchlists.length - 1
          this.snackbarText = `Successfully created watchlist ${this.watchlistName}`
          this.snackbarType = 'success'
          this.showSnackbar = true
        })
        .catch(err => {
          this.snackbarText = `Error creating watchlist: ${err.message}`
          this.snackbarType = 'error'
          this.showSnackbar = true
        })
        .finally(() => {
          this.stocks = []
          this.dialog = false
        })
    },
    removeWatchlist() {
      this.watchlistName = `${this.watchlists[this.selectedWatchlist].name}`
      usersCollection
        .doc(auth.currentUser.uid)
        .collection('watchlists')
        .doc(this.watchlists[this.selectedWatchlist].id)
        .delete()
        .then(() => {
          this.watchlists.splice(this.selectedWatchlist, 1)
          for (
            let i = this.selectedWatchlist;
            i < this.watchlists.length;
            ++i
          ) {
            this.watchlists[i].pos = i
          }
          this.selectedWatchlist = undefined
          this.snackbarText = `Successfully deleted watchlist ${this.watchlistName}`
          this.snackbarType = 'success'
          this.showSnackbar = true
        })
        .catch(err => {
          this.snackbarText = `Error deleting watchlist: ${err.message}`
          this.snackbarType = 'error'
          this.showSnackbar = true
        })
        .finally(() => {
          this.stocks = []
          this.dialog = false
        })
    },
    watchlistChanged() {
      if (this.selectedWatchlist !== 0) {
        this.loading = true
        this.yfinancelive.stop()
        this.fetchStockData()
      } else {
        this.dialog = true
      }
    },
    close() {
      this.dialog = false
      if (!this.deleteWatchlist) this.$refs.form.resetValidation()
      this.$nextTick(() => {
        this.deleteWatchlist = false
        if (this.selectedWatchlist === 0) {
          this.selectedWatchlist = undefined
        }
        this.watchlistName = null
      })
    },
    addTickers() {
      this.watchlists[this.selectedWatchlist].constituents = _.union(
        this.watchlists[this.selectedWatchlist].constituents,
        this.selectedTickers
      )
      usersCollection
        .doc(auth.currentUser.uid)
        .collection('watchlists')
        .doc(this.watchlists[this.selectedWatchlist].id)
        .update({
          constituents: this.watchlists[this.selectedWatchlist].constituents,
        })
        .catch(err => {
          this.snackbarText = `Error updating watchlist: ${err.message}`
          this.snackbarType = 'error'
          this.showSnackbar = true
        })
        .then(() => {
          this.snackbarText = `Successfully updated watchlist ${
            this.watchlists[this.selectedWatchlist].name
          }`
          this.snackbarType = 'success'
          this.showSnackbar = true
          this.selectedTickers = []
          this.tickerQuery = ''
          this.watchlistChanged()
        })
    },
    removeSelectedTicker(item) {
      const index = this.selectedTickers.indexOf(item.symbol)
      if (index >= 0) this.selectedTickers.splice(index, 1)
    },
    deleteTicker(item) {
      this.deleteItem = Object.assign({}, item)
      this.deleteIndex = this.stocks.indexOf(item)
      this.dialogDelete = true
    },
    deleteTickerConfirm() {
      if (this.deleteIndex >= 0)
        this.watchlists[this.selectedWatchlist].constituents.splice(
          this.deleteIndex,
          1
        )
      usersCollection
        .doc(auth.currentUser.uid)
        .collection('watchlists')
        .doc(this.watchlists[this.selectedWatchlist].id)
        .update({
          constituents: this.watchlists[this.selectedWatchlist].constituents,
        })
        .then(() => {
          this.stocks.splice(this.deleteIndex, 1)
          this.snackbarText = `Successfully deleted ticker ${this.deleteItem.symbol}!`
          this.snackbarType = 'success'
          this.showSnackbar = true
          this.dialogDelete = false
        })
        .catch(err => {
          this.snackbarText = `Error deleting ticker ${this.deleteItem.symbol}: ${err.message}`
          this.snackbarType = 'error'
          this.showSnackbar = true
        })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.deleteItem = {}
        this.deleteIndex = -1
      })
    },
  },
  mounted() {
    this.fetchWatchlists().then(() => {
      if (this.watchlists.length > 1) this.fetchStockData()
    })
  },
  beforeRouteLeave(_to, _from, next) {
    if (this.yfinancelive) this.yfinancelive.stop()
    next()
  },
}
</script>
